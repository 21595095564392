<template>
  <div>
    <div class="d-flex justify-content-between">
      <h1>Manage Workflow</h1>
      <div class="d-flex justify-content-between">
        <h1 class="mr-3">Total : {{ this.getWorkflows.length }}</h1>
        <h1 v-if="searchTerms != ''">
          Filtered results : {{ this.workflowList.length }}
        </h1>
        <h1 v-else>Filtered results : 0</h1>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-between pt-4">
      <b-row class="d-flex justify-content-between pb-1">
        <b-col class="d-flex justify-content-start">
          <b-dropdown
            v-if="selected.length > 0"
            :text="selected.length + ' is selected'"
            size="sm"
            class="mr-1"
          >
            <b-dropdown-item @click="selectAllRows"
              >Select All
            </b-dropdown-item>
            <!--  <b-dropdown-item @click="clearSelected"
              >Unselect All
            </b-dropdown-item> -->
            <b-dropdown-item @click="clearSelected"
              >Clear Selections
            </b-dropdown-item>
            <b-dropdown-item @click="setActive">Set Active </b-dropdown-item>
            <b-dropdown-item @click="setInactive"
              >Set Inactive
            </b-dropdown-item>
            <b-dropdown-item @click="deleteSelected"
              >Delete Selected
            </b-dropdown-item>
          </b-dropdown>
          <router-link
            :to="{
              name: 'audit-logs'
            }"
            v-slot="{ href, route, navigate }"
            custom
          >
            <b-button
              size="sm"
              variant="secondary"
              :href="href"
              @click="navigate"
              v-b-tooltip.hover
              title="Audit Log"
            >
              <i class="fa fa-history" aria-hidden="true"></i>
            </b-button>
          </router-link>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            @click="addNewWorkflow"
          >
            <i class="fa fa-plus mr-1"></i>Add Workflow
          </b-button>
          <b-form-input
            id="search_bar"
            size="sm"
            type="text"
            class="w-70"
            placeholder="search by event name...."
            v-model="searchTerms"
          ></b-form-input>
        </b-col>
      </b-row>

      <!-- Main table element -->
      <div class="d-flex justify-content-between">
        <!-- :filter-included-fields="filterOn" -->
        <b-table
          :busy="busy"
          :items="getWorkflows"
          :fields="tableFields"
          :select-mode="selectMode"
          :filter="searchTerms"
          :filter-function="filterTable"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          ref="selectableTable"
          class="tableBorder"
          stacked="md"
          striped
          responsive
          small
          show-empty
          selectable
          @row-selected="onRowSelected"
        >
          <template #table-busy>
            <div class="text-center text-white my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <template #cell(event_type)="row">
            <strong>When</strong>
            {{ row.item.event_type }}
          </template>
          <template #cell(triggers)="row">
            <div
              v-for="(
                valueInArray, propertyName, index
              ) in triggersRemoveScheduleFormatter(row.item.triggers)"
              :key="'trigger_' + propertyName"
            >
              <div v-if="propertyName !== 'schedule'">
                <span>
                  <strong>{{ index === 0 ? "At" : "&" }}</strong>
                </span>
                <span
                  class="ml-1"
                  v-for="item in valueInArray"
                  :key="'trigger_valueInArray_' + item"
                >
                  <b-badge class="p-1 h6">
                    {{ propertyName | humanizeText }} :
                    {{ triggerCategoryLabelById(propertyName, item) }}
                  </b-badge>
                </span>
              </div>
            </div>
            <div
              v-for="(value, propertyName) in row.item.triggers"
              :key="'trigger2_' + propertyName"
            >
              <div
                v-if="propertyName === 'schedule'"
                @click="showWorkflowScheduleModal(value)"
              >
                <i class="fas fa-calendar"></i><u>View Schedule</u>
              </div>
            </div>
          </template>
          <template #cell(actions)="row">
            <div
              v-for="(action, index) in showActionBasedOnPk(row.item.pk)"
              :key="'action_' + action.pk"
            >
              <span>
                <strong>{{ index === 0 ? "Do" : "&" }}</strong>
              </span>
              <span class="ml-1">
                <b-badge class="p-1 h6">
                  {{ action.name | humanizeText }}
                </b-badge>
              </span>
            </div>
            <div
              v-if="
                showActionBasedOnPk(row.item.pk) &&
                Object.keys(showActionBasedOnPk(row.item.pk)).length === 0
              "
            >
              Do Nothing
            </div>
          </template>
          <template #cell(status)="row">
            <b-form-checkbox
              v-model="row.item.boolStatus"
              size="lg"
              switch
              @change="workflowStatusHandler(row.item)"
            >
            </b-form-checkbox>
            <!--              Switch Checkbox <b>(Checked: {{ row.item.boolStatus }})</b>-->
          </template>
          <template #cell(do)="row">
            <router-link
              :to="{
                name: 'settings/workflow/update',
                params: { workflow_id: row.item.pk }
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-button
                variant="secondary"
                size="sm"
                :href="href"
                @click="navigate"
                title="Update Workflow"
                class="mr-1"
              >
                <i class="fa fa-edit"></i>
              </b-button>
            </router-link>
            <b-button
              variant="danger"
              size="sm"
              v-b-tooltip.hover
              title="Delete Workflow"
              @click="deleteWorkflowHandler(row.item)"
            >
              <i class="fa fa-trash-alt"></i>
            </b-button>
          </template>
        </b-table>
      </div>

      <!-- pagination -->
      <div class="d-flex justify-content-between" v-if="!busy && !searchTerms">
        <div class="d-flex">
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            @change="handlePerPageChange"
          ></b-form-select>
          Per Page
        </div>

        <div class="d-flex">
          <b-pagination
            size="sm"
            v-if="perPage !== 'all'"
            class="d-flex"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>

    <!-- schedule modal -->
    <b-modal
      id="workflow_schedule_modal"
      ref="workflow_schedule_modal"
      title="Workflow Schedule"
      size="xl"
      @show="reset_workflow_schedule_modal"
      @hidden="reset_workflow_schedule_modal"
      @ok="handle_ok_workflow_schedule_modal"
    >
      <table class="table-responsive">
        <tbody class="table-responsive">
          <tr>
            <th><strong>All</strong></th>
            <td
              v-for="(day2, key2) in this.groupedWorkflowSchedulesExpand[
                this.day_of_month
              ]"
              :key="key2"
            >
              <strong>{{ key2 }}</strong>
            </td>
          </tr>
          <tr v-for="(day, key) in groupedWorkflowSchedulesExpand" :key="key">
            <th scope="row">{{ getDay(key) }}</th>
            <td v-for="(segment, index) in day" :key="day + '_' + index">
              <div
                :class="segment.status === 1 ? 'bg-success' : 'bg-dark'"
                :style="{ height: '30px', width: '30px' }"
              ></div>
            </td>
          </tr>
          <tr class="d-flex justify-content-center">
            <td>
              <div
                class="m-1 d-flex bg-success"
                :style="{ height: '30px', width: '30px' }"
                @click="handleSetScheduleChange(true)"
              >
                <br />
                &nbsp;
              </div>
              <strong>ON</strong>
            </td>
            <td>
              <div
                class="m-1 d-flex bg-dark"
                :style="{ height: '30px', width: '30px' }"
                @click="handleSetScheduleChange(false)"
              >
                <br />
                &nbsp;
              </div>
              <strong>OFF</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";
import _ from "lodash";

export default {
  components: {},
  data: function () {
    return {
      tableFields: [
        {
          key: "selected",
          label: ""
        },
        {
          key: "index",
          label: "No"
        },
        {
          key: "event_type",
          label: "Event",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "triggers",
          label: "Condition"
        },
        {
          key: "actions",
          label: "Workflows"
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "do", label: "Actions" }
      ],
      busy: false,
      searchTerms: "",
      totalRows: 0,
      currentPage: 1,
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: ["event_type"],
      perPage: 10000,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],

      selectMode: "multi",
      selected: [],

      workflowList: [],

      groupedWorkflowSchedulesExpand: {},
      day_of_month: null,
      filterCount: 0
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getWorkflows: "workflowModule/getWorkflows",
      getWorkflowsActions: "workflowModule/getWorkflowsActions",
      getEventTypes: "workflowModule/getEventTypes",
      getTriggerCategories: "workflowModule/getTriggerCategories",
      getFilterWorkflows: "workflowModule/getFilterWorkflows"
    }),
    triggerCategoryLabelById: function () {
      return (trigger, id) => {
        try {
          if (
            Object.prototype.hasOwnProperty.call(
              this.getTriggerCategories,
              trigger
            )
          ) {
            for (
              let i = 0;
              i < this.getTriggerCategories[trigger].length;
              i++
            ) {
              if (this.getTriggerCategories[trigger][i].value === id) {
                return this.getTriggerCategories[trigger][i].name;
              }
            }
          }
        } catch (e) {
          //console.log("triggerCategoryLabelById e", e);
        }
        return "-";
      };
    }
  },
  watch: {
    $route: function () {
      if (this.$route.query.per_page === "all") {
        this.currentPage = 1;
        this.perPage = this.$route.query.per_page;
      } else {
        this.currentPage = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page
          ? parseInt(this.$route.query.per_page)
          : 5;
      }
      this.$store.dispatch("workflowModule/fetchWorkflows");
    },
    getWorkflows: function (newValue) {
      for (let key in newValue) {
        newValue[key].event_type = this.eventTypeLabel(
          newValue[key].event_type,
          newValue[key].subevent_type
        );
      }
    },
    getFilterWorkflows: function (newValue) {
      this.workflowList.push(newValue);
    }
  },
  created() {
    if (this.$route.query.per_page === "all") {
      this.currentPage = 1;
      this.perPage = this.$route.query.per_page;
    } else {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 5;
    }
  },
  mounted: function () {
    this.$store.dispatch("workflowModule/fetchWorkflows");
    this.$store.dispatch("workflowModule/fetchEventTypes");
    this.$store.dispatch("workflowModule/fetchTriggerCategories");
  },
  methods: {
    getMonth(day2) {
      console.log("day2", day2);
    },
    getDay(key) {
      if (key == 1) {
        return "Mon";
      }
      if (key == 2) {
        return "Tue";
      }
      if (key == 3) {
        return "Wed";
      }
      if (key == 4) {
        return "Thur";
      }
      if (key == 5) {
        return "Fri";
      }
      if (key == 6) {
        return "Sat";
      }
      if (key == 7) {
        return "Sun";
      }
    },
    filterTable(row, filter) {
      let capitalize_letter_filter = _.startCase(filter);
      if (row.event_type.indexOf(capitalize_letter_filter) !== -1) {
        this.$store.dispatch("workflowModule/fetchFilterWorkflows", row);
        return true;
      } else {
        this.workflowList = [];
        return false;
      }
    },
    //handle page change
    handlePageChange(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      if (pageSize === "all") {
        this.$router.push({
          ...this.$route,
          query: {
            per_page: pageSize
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            per_page: parseInt(pageSize)
          }
        });
      }
    },

    eventTypeLabel(event_id, sub_event_id) {
      if (sub_event_id) {
        let subEventName = this.subEventName(event_id, sub_event_id);

        return subEventName;
      } else {
        let eventName = "Any " + this.eventName(event_id) + " status";
        return eventName;
      }
    },

    eventName(event_id) {
      if (event_id === null) {
        return this.getEventTypes[0].name;
      }
      for (let i = 0; i < this.getEventTypes.length; i++) {
        if (this.getEventTypes[i].value === event_id) {
          return this.getEventTypes[i].name;
        }
      }
    },

    subEventName(event_id, subevent_id) {
      for (let i = 0; i < this.getEventTypes.length; i++) {
        if (this.getEventTypes[i].value === event_id) {
          let subevents = this.getEventTypes[i]["subevents"];
          for (let j = 0; j < subevents.length; j++) {
            if (subevents[j].value === subevent_id) {
              return subevents[j].name;
            }
          }
        }
      }
    },

    triggersRemoveScheduleFormatter(triggers) {
      let cloneTriggers = Object.assign({}, triggers);
      delete cloneTriggers["schedule"];
      return cloneTriggers;
    },

    onRowSelected(items) {
      this.selected = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    setActive() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to update the status of the selected objects to active?",
          {
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            for (let index1 in this.selected) {
              // ! is because the client side is already updated, but server side havent updated
              //console.log("activating");

              /*  let object = {
                ...this.selected[index],
                status: 1
              };

              console.log("object", object); */

              this.$store.dispatch(
                "workflowModule/updateSelectedWorkflowStatus",
                {
                  ...this.selected[index1],
                  status: 1
                }
              );
            }
          } else {
            // revert to previous state
            //console.log("revert to previous state");
            this.$store.dispatch("workflowModule/fetchWorkflows");
          }
        })
        .catch((err) => {
          // An error occurred
          //console.log("err", err);
        });
    },
    setInactive() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to update the status of the selected objects to inactive?",
          {
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            //console.log("this.selected", this.selected);
            for (let index2 in this.selected) {
              // ! is because the client side is already updated, but server side havent updated
              console.log("deactivating");

              /*  let object = {
                ...this.selected[index],
                status: 0
              };

              console.log("object", object); */

              this.$store.dispatch(
                "workflowModule/updateSelectedWorkflowStatus",
                {
                  ...this.selected[index2],
                  status: 0
                }
              );
            }
          } else {
            // revert to previous state
            //console.log("revert to previous state");
            this.$store.dispatch("workflowModule/fetchWorkflows");
          }
        })
        .catch((err) => {
          // An error occurred
          //console.log("err", err);
        });
    },
    deleteSelected() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete the selected objects?",
          {
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            for (let index3 in this.selected) {
              this.$store.dispatch(
                "workflowModule/deleteSelectedWorkflow",
                this.selected[index3]
              );
            }
          } else {
            // revert to previous state
            //console.log("revert to previous state");
            this.$store.dispatch("workflowModule/fetchWorkflows");
          }
        })
        .catch((err) => {
          // An error occurred
          //console.log("err", err);
        });
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2);
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2);
    },

    showActionBasedOnPk(pk) {
      try {
        return this.getWorkflowsActions[pk];
      } catch (e) {
        return {};
      }
    },

    workflowStatusHandler: function (data) {
      let action = "";
      if (data.status === 1) {
        // client side is already updated, but server side havent updated
        action = "deactivate";
      } else {
        action = "activate";
      }
      this.$bvModal
        .msgBoxConfirm("Confirm " + action + " workflow ?", {
          centered: true
        })
        .then((value) => {
          if (value) {
            if (data.status === 1) {
              // ! is because the client side is already updated, but server side havent updated
              console.log("deactivating");

              this.$store.dispatch(
                "workflowModule/updateSelectedWorkflowStatus",
                {
                  ...data,
                  status: 0
                }
              );
            } else {
              console.log("activating");

              this.$store.dispatch(
                "workflowModule/updateSelectedWorkflowStatus",
                {
                  ...data,
                  status: 1
                }
              );
            }
          } else {
            // revert to previous state
            //console.log("revert to previous state");
            this.$store.dispatch("workflowModule/fetchWorkflows");
          }
        })
        .catch((err) => {
          // An error occurred
          //console.log("err", err);
        });
    },

    deleteWorkflowHandler: function (data) {
      this.$bvModal
        .msgBoxConfirm("Confirm delete workflow ?", {
          centered: true
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch("workflowModule/deleteSelectedWorkflow", data);
          }
        })
        .catch((err) => {
          // An error occurred
          //console.log("err", err);
        });
    },

    addNewWorkflow: function () {
      let $this = this;
      let API_URL = `${$this.getAPIServerURL}/api/workflows/`;
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, {
          title: null,
          event_type: 1,
          subevent_type: null
        })
        .then((data) => {
          let validUrl = "";
          let result = data.result;

          if (Object.keys(result).length !== 0) {
            if (result != undefined) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined) {
                  validUrl = key;
                  break outer_loop;
                }
              }
            }
            if (result[validUrl] != undefined) {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: "The new workflow is created",
                message_type: "success"
              });

              $this.$router
                .push({
                  name: "settings/workflow/create",
                  params: { workflow_id: result[validUrl].pk }
                })
                .catch(() => {});
            }
          } else {
            //
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },

    showWorkflowScheduleModal(data) {
      let groupedSchedules = this.groupByDay(data);

      let groupedSchedulesExpand = {};

      for (let day in groupedSchedules) {
        let segments = [];
        for (let i = 0; i < groupedSchedules[day].length; i++) {
          let temp = this.segmentExpander(groupedSchedules[day][i]);
          segments = segments.concat(temp);
        }
        groupedSchedulesExpand[day] = segments;
      }

      this.groupedWorkflowSchedulesExpand = groupedSchedulesExpand;

      outer_loop: for (let key in this.groupedWorkflowSchedulesExpand) {
        this.day_of_month = key;
        console.log("key", key);
        break outer_loop;
      }

      console.log(
        "groupedWorkflowSchedulesExpand",
        this.groupedWorkflowSchedulesExpand[this.day_of_month]
      );

      this.$refs["workflow_schedule_modal"].show();
    },

    reset_workflow_schedule_modal() {
      //this.groupedWorkflowSchedulesExpand = {};
    },
    handle_ok_workflow_schedule_modal(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handle_submit_workflow_schedule_modal();
    },
    handle_submit_workflow_schedule_modal() {
      // // Exit when the form isn't valid
      // if (!this.check_http_api_validity()) {
      //   return;
      // }
      //
      // this.updateSelectedActionData("HTTP API", this.http_api_form);

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("workflow_schedule_modal");
      });
    },

    groupByDay(schedules) {
      return _.groupBy(schedules, (schedule) => schedule.day_of_week);
    },
    segmentExpander(timeSegment) {
      let output = [];
      let tempStart = timeSegment.start_time;
      let tempEnd = timeSegment.start_time;
      while (tempStart !== timeSegment.end_time) {
        tempEnd = tempStart + 3600;
        output.push({
          ...timeSegment,
          start_time: tempStart,
          end_time: tempEnd
        });
        tempStart = tempEnd;
      }
      return output;
    }
  }
};
</script>

<style lang="scss">
.calendars {
  background: var(--colour-nav-bg);
}

.drp-buttons {
  background: var(--colour-nav-bg);
}

.calendar-table {
  color: var(--colour-overlay-gray-dark);
}
</style>
